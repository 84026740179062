<template>
  <section>
    <input-typeahead
        :placeholder="$t('__helpcentersearchbar')"
        v-model="searchbar"
        :data="questions"
        :serializer="item => item.name"
        @hit="gotoQuestion($event)"
    />
  </section>
</template>

<script>
import { BFormInput } from 'bootstrap-vue'
import axios from "axios";
import debounce from "lodash/debounce";
import InputTypeahead from "Components/ui/HelpCenterTypeahead/InputTypeahead/InputTypeahead";

export default {
  name: "HelpCenterSearchBar",
  components:{
    BFormInput,
    InputTypeahead
  },
  data() {
    return {
      searchbar: '',
      selectedQuestion: null,
      questions: []
    }
  },
  props : {
    typeFAQ : {
      type: String,
      default : "client"
    }
  },
  methods: {
    gotoQuestion : function(question) {
      if ( question && question.slug ) {
        window.location = window.location.origin +question.slug ;
      }
    },
    getQuestions : debounce(async function(query){
      let result = "success";
      const searchTerm = query;
      if ( query) {
        const url = '/ApiFreshdesk.do';
        const { data } = await axios.get(url, {params:{filter: query, type : this.typeFAQ}})
        this.questions = data.data;
      }else{
        this.questions = [];
        result = "failed";
      }

      if(this.questions.length === 0) {
        result = "failed";
      }

      trackNotContextualisedEventGA4(
          'search',
          this.typeFAQ === "client" ? 'client' : 'owner',
          'faq',
          'search',
          {
            result : result,
            search_term : searchTerm,
          }

      );

    },1000),
  },
  watch: {
    // When the query value changes, fetch new results from
    // the API - in practice this action should be debounced
    searchbar : function(newQuery) { this.getQuestions(newQuery)}
  },
}
</script>

<style scoped>

</style>
